import {
  Card,
  CardBody,
  Container,
  Divider,
  Flex,
  HStack,
  Icon,
  Switch,
  Text,
} from "@chakra-ui/react";
import useUser from "../lib/useUser";
import ProtectedPage from "../components/ProtectedPage";

import EditProfileCard from "../components/EditProfileCard";
import ChangePasswordCard from "../components/ChangePasswordCard";
import DeleteUserCard from "../components/DeleteUserCard";
import TermsAgreementProtected from "../components/TermsAgreementProtected";
import { FaBell } from "react-icons/fa";

export default function Me() {
  const { user } = useUser();
  return (
    <ProtectedPage>
      <TermsAgreementProtected>
        <Container>
          {user && (
            <>
              <EditProfileCard user={user} />
              <Divider my={3} />
              {user.has_usable_password && (
                <>
                  <ChangePasswordCard />
                  <Divider my={3} />
                </>
              )}
              <DeleteUserCard />
            </>
          )}
        </Container>
      </TermsAgreementProtected>
    </ProtectedPage>
  );
}
