import { initializeApp } from "firebase/app";
import {
  getMessaging,
  getToken,
  onMessage,
  isSupported,
  Messaging,
} from "firebase/messaging";
import { getAnalytics } from "firebase/analytics";
import { deleteFCMToken, getFCMToken, saveFCMToken } from "./routes/api";
import { AxiosError } from "axios";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY || "",
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN || "",
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID || "",
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET || "",
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID || "",
  appId: process.env.REACT_APP_FIREBASE_APP_ID || "",
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID || "",
};

let app: ReturnType<typeof initializeApp> | null = null;
let messaging: Messaging | null = null;

(async () => {
  const supported = await isSupported();
  if (supported) {
    app = initializeApp(firebaseConfig);
    messaging = getMessaging(app);
    getAnalytics(app);

    // 포그라운드 메시지 수신 처리
    onMessage(messaging, (payload) => {
      console.log("Message received. ", payload);
      alert(`새 메시지: ${payload.data?.title}`);
    });
  }
})();

export const requestSaveFCMToken = async (permission: string) => {
  if (permission === "granted" && messaging) {
    try {
      const currentToken = await getToken(messaging, {
        vapidKey: process.env.REACT_APP_FIREBASE_VAPID_KEY,
      });
      if (currentToken) {
        // 서버에 FCM 토큰 전송
        await saveFCMToken(currentToken);
        console.log("서버에 FCM 토큰 저장 성공");
      } else {
        console.log("FCM 토큰을 가져올 수 없습니다.");
        alert("오류가 발생하였습니다. 다시 시도해주세요.");
      }
    } catch (error) {
      console.error("FCM 토큰 처리 중 오류 발생:", error);
      alert("오류가 발생하였습니다. 다시 시도해주세요.");
    }
  } else {
    console.log("알림 권한이 거부되었습니다.");
  }
};

export const requestDeleteFCMToken = async () => {
  if (messaging) {
    try {
      const currentToken = await getToken(messaging, {
        vapidKey: process.env.REACT_APP_FIREBASE_VAPID_KEY,
      });
      if (currentToken) {
        // 서버에 FCM 토큰 전송
        await deleteFCMToken(currentToken);
        console.log("FCM 토큰 삭제 완료");
      } else {
        console.log("FCM 토큰을 가져올 수 없습니다.");
        alert("오류가 발생하였습니다. 다시 시도해주세요.");
      }
    } catch (error) {
      console.error("FCM 토큰 처리 중 오류 발생:", error);
      alert("오류가 발생하였습니다. 다시 시도해주세요.");
    }
  }
};

export const requestGetFCMToken = async () => {
  if (messaging) {
    try {
      const currentToken = await getToken(messaging, {
        vapidKey: process.env.REACT_APP_FIREBASE_VAPID_KEY,
      });
      if (currentToken) {
        // 서버에 FCM 토큰 전송
        try {
          await getFCMToken(currentToken);
          return true;
        } catch (error) {
          if (error instanceof AxiosError) {
            return false;
          }
        }
      } else {
        console.log("FCM 토큰을 가져올 수 없습니다.");
      }
    } catch (error) {
      console.error("FCM 토큰 처리 중 오류 발생:", error);
    }
  }
};

if ("serviceWorker" in navigator) {
  navigator.serviceWorker
    .getRegistration("/firebase-cloud-messaging-push-scope/")
    .then((registration) => {
      if (registration) {
        // 업데이트 이벤트 리스너 등록
        registration.addEventListener("updatefound", () => {
          const newWorker = registration.installing;

          if (newWorker) {
            newWorker.addEventListener("statechange", () => {
              if (newWorker.state === "installed") {
                if (navigator.serviceWorker.controller) {
                  console.log("Service Worker updated successfully.");
                  const permission = Notification.permission;
                  requestSaveFCMToken(permission);
                } else {
                  console.log(
                    "New Service Worker installed, but not yet controlling."
                  );
                  const permission = Notification.permission;
                  requestSaveFCMToken(permission);
                }
              }
            });
          }
        });

        registration.update();
      } else {
        console.log("No Service Worker found for this scope.");
      }
    });
}
