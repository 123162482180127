import { Box, Text } from "@chakra-ui/react";

export default function NegativeValues() {
  return (
    <Box px="12" mt="3">
      <Text textAlign={"center"} lineHeight={2} letterSpacing={0.5}>
        정체됨 편견 독단 억압 구속 불공정 차별 불평등 분열 충돌 냉담 단절 소외
        고립 비겁함 무책임 보수적 무지 무관심 비관 갈등 퇴보 무시 경멸 탐욕
        이기심 포기 회피 고착 고집 결핍 조잡함 배제 무지 피상적 획일성 틀에 박힘
        무질서 위선 지배 폭력성 불균형 혼란 파괴 고정관념
      </Text>
    </Box>
  );
}
