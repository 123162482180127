import {
  Box,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Collapse,
  Container,
  Divider,
  Flex,
  Grid,
  GridItem,
  Icon,
  ListItem,
  Skeleton,
  Text,
  UnorderedList,
  useColorMode,
  VStack,
} from "@chakra-ui/react";
import { QueryKey, useInfiniteQuery } from "@tanstack/react-query";
import {
  getNegExperienceFirstAnswers,
  getPosExperienceFirstAnswers,
  getQuesAnswers,
} from "./api";

import { BeatLoader } from "react-spinners";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import {
  FaAngleDown,
  FaAngleRight,
  FaAngleUp,
  FaChevronLeft,
  FaCloud,
  FaFeatherAlt,
  FaLeaf,
} from "react-icons/fa";
import ProtectedPage from "../components/ProtectedPage";

interface IQuesAnswer {
  pk: number;
  sub_question: string;
  sub_answer: string;
  type: "positive" | "negative";
  question_pk: number;
}

interface IQuesAnswerPage {
  count: number;
  next: string | null;
  previous: string | null;
  results: IQuesAnswer[];
}

interface IQuesAnswerPaginatedResponse {
  pageParams: number[];
  pages: IQuesAnswerPage[];
}

interface IPosExperience {
  pk: number;
  positive_experience: string;
}

interface IPosExperiencePage {
  count: number;
  next: string | null;
  previous: string | null;
  results: IPosExperience[];
}

interface IPosExperiencePaginatedResponse {
  pageParams: number[];
  pages: IPosExperiencePage[];
}

interface INegExperience {
  pk: number;
  negative_experience: string;
}

interface INegExperiencePage {
  count: number;
  next: string | null;
  previous: string | null;
  results: INegExperience[];
}

interface INegExperiencePaginatedResponse {
  pageParams: number[];
  pages: INegExperiencePage[];
}

export default function MyDiscoveries() {
  const [isQuesAnswerCollapsed, setIsQuesAnswerCollapsed] = useState(false);
  const [isPosExpCollapsed, setIsPosExpCollapsed] = useState(false);
  const [isNegExpCollapsed, setIsNegExpCollapsed] = useState(false);

  useEffect(() => {
    const savedState1 = localStorage.getItem(`Discoveries-1-collapsed`);
    if (savedState1 !== null) {
      setIsQuesAnswerCollapsed(JSON.parse(savedState1));
    }
    const savedState2 = localStorage.getItem(`Discoveries-2-collapsed`);
    if (savedState2 !== null) {
      setIsPosExpCollapsed(JSON.parse(savedState2));
    }
    const savedState3 = localStorage.getItem(`Discoveries-3-collapsed`);
    if (savedState3 !== null) {
      setIsNegExpCollapsed(JSON.parse(savedState3));
    }
  }, []);

  const toggleQuesAnswerCollapse = () => {
    const newState = !isQuesAnswerCollapsed;
    setIsQuesAnswerCollapsed(newState);
    localStorage.setItem(`Discoveries-1-collapsed`, JSON.stringify(newState));
  };

  const togglePosExpCollapse = () => {
    const newState = !isPosExpCollapsed;
    setIsPosExpCollapsed(newState);
    localStorage.setItem(`Discoveries-2-collapsed`, JSON.stringify(newState));
  };

  const toggleNegExpCollapse = () => {
    const newState = !isNegExpCollapsed;
    setIsNegExpCollapsed(newState);
    localStorage.setItem(`Discoveries-3-collapsed`, JSON.stringify(newState));
  };

  const {
    isLoading: isQuesAnswerLoading,
    data: quesAnswerData,
    fetchNextPage: fetchQuesAnswerNextPage,
    hasNextPage: hasQuesAnswerNextPage,
    isFetchingNextPage: isQuesAnswerFetchingNextPage,
  } = useInfiniteQuery<
    IQuesAnswerPage,
    Error,
    IQuesAnswerPaginatedResponse,
    QueryKey,
    number
  >({
    queryKey: ["QuesAnswers"],
    queryFn: ({ pageParam = 1 }) => getQuesAnswers(pageParam),
    initialPageParam: 1,
    getNextPageParam: (lastPage, allPages) => {
      return lastPage.next ? allPages.length + 1 : undefined;
    },
  });

  const {
    isLoading: isPosExpLoading,
    data: PosExpData,
    fetchNextPage: fetchPosExpNextPage,
    hasNextPage: hasPosExpNextPage,
    isFetchingNextPage: isPosExpFetchingNextPage,
  } = useInfiniteQuery<
    IPosExperiencePage,
    Error,
    IPosExperiencePaginatedResponse,
    QueryKey,
    number
  >({
    queryKey: ["positiveExperienceFirstAnswers"],
    queryFn: ({ pageParam = 1 }) => getPosExperienceFirstAnswers(pageParam),
    initialPageParam: 1,
    getNextPageParam: (lastPage, allPages) => {
      return lastPage.next ? allPages.length + 1 : undefined;
    },
  });

  const {
    isLoading: isNegExpLoading,
    data: NegExpData,
    fetchNextPage: fetchNegExpNextPage,
    hasNextPage: hasNegExpNextPage,
    isFetchingNextPage: isNegExpFetchingNextPage,
  } = useInfiniteQuery<
    INegExperiencePage,
    Error,
    INegExperiencePaginatedResponse,
    QueryKey,
    number
  >({
    queryKey: ["negativeExperienceFirstAnswers"],
    queryFn: ({ pageParam = 1 }) => getNegExperienceFirstAnswers(pageParam),
    initialPageParam: 1,
    getNextPageParam: (lastPage, allPages) => {
      return lastPage.next ? allPages.length + 1 : undefined;
    },
  });

  const { colorMode } = useColorMode();
  return (
    <ProtectedPage>
      <Container mb="10">
        <Flex>
          <Link to="/">
            <Flex py="5" px="1">
              <Icon as={FaChevronLeft} />
            </Flex>
          </Link>
        </Flex>
        <VStack w="100%">
          <Card w="100%">
            <CardHeader>
              <Grid gridTemplateColumns={"1fr 1fr 1fr"}>
                <GridItem alignContent={"center"} fontSize={"xl"}>
                  <Flex h="100%">
                    <Flex
                      h="100%"
                      cursor="pointer"
                      onClick={toggleQuesAnswerCollapse}
                      alignItems={"center"}
                      justifyContent={"center"}
                    >
                      <Icon
                        as={isQuesAnswerCollapsed ? FaAngleDown : FaAngleUp}
                      />
                    </Flex>
                  </Flex>
                </GridItem>
                <VStack>
                  <Text fontSize={"xl"}>질문성찰</Text>
                </VStack>
                <Box></Box>
              </Grid>
            </CardHeader>
            <Collapse in={!isQuesAnswerCollapsed} animateOpacity unmountOnExit>
              <CardBody>
                <Divider mb="3" />
                {isQuesAnswerLoading ? (
                  <UnorderedList>
                    <VStack w="100%" alignItems={"flex-start"}>
                      <Box alignItems={"flex-start"} mb="3">
                        <Skeleton
                          h="20px"
                          w="200px"
                          borderRadius={"lg"}
                          mb="1"
                        />
                        <Skeleton h="20px" w="250px" borderRadius={"lg"} />
                      </Box>
                      <Box alignItems={"flex-start"} mb="3">
                        <Skeleton
                          h="20px"
                          w="200px"
                          borderRadius={"lg"}
                          mb="1"
                        />
                        <Skeleton h="20px" w="250px" borderRadius={"lg"} />
                      </Box>
                      <Box alignItems={"flex-start"} mb="3">
                        <Skeleton
                          h="20px"
                          w="200px"
                          borderRadius={"lg"}
                          mb="1"
                        />
                        <Skeleton h="20px" w="250px" borderRadius={"lg"} />
                      </Box>
                      <Box alignItems={"flex-start"} mb="3">
                        <Skeleton
                          h="20px"
                          w="200px"
                          borderRadius={"lg"}
                          mb="1"
                        />
                        <Skeleton h="20px" w="250px" borderRadius={"lg"} />
                      </Box>
                      <Box alignItems={"flex-start"} mb="3">
                        <Skeleton
                          h="20px"
                          w="200px"
                          borderRadius={"lg"}
                          mb="1"
                        />
                        <Skeleton h="20px" w="250px" borderRadius={"lg"} />
                      </Box>
                      <Box alignItems={"flex-start"} mb="3">
                        <Skeleton
                          h="20px"
                          w="200px"
                          borderRadius={"lg"}
                          mb="1"
                        />
                        <Skeleton h="20px" w="250px" borderRadius={"lg"} />
                      </Box>
                      <Box alignItems={"flex-start"} mb="3">
                        <Skeleton
                          h="20px"
                          w="200px"
                          borderRadius={"lg"}
                          mb="1"
                        />
                        <Skeleton h="20px" w="250px" borderRadius={"lg"} />
                      </Box>
                      <Box alignItems={"flex-start"} mb="3">
                        <Skeleton
                          h="20px"
                          w="200px"
                          borderRadius={"lg"}
                          mb="1"
                        />
                        <Skeleton h="20px" w="250px" borderRadius={"lg"} />
                      </Box>
                      <Box alignItems={"flex-start"} mb="3">
                        <Skeleton
                          h="20px"
                          w="200px"
                          borderRadius={"lg"}
                          mb="1"
                        />
                        <Skeleton h="20px" w="250px" borderRadius={"lg"} />
                      </Box>
                      <Box alignItems={"flex-start"} mb="3">
                        <Skeleton
                          h="20px"
                          w="200px"
                          borderRadius={"lg"}
                          mb="1"
                        />
                        <Skeleton h="20px" w="250px" borderRadius={"lg"} />
                      </Box>
                    </VStack>
                  </UnorderedList>
                ) : (
                  <>
                    {quesAnswerData?.pages[0].count === 0 ? (
                      <Box>
                        <Flex mb="3" w="100%" justifyContent={"center"}>
                          아직 성찰이 없습니다.
                        </Flex>
                        <Link
                          style={{ width: "100%" }}
                          to={"/reflection/questions/select"}
                        >
                          <Button
                            variant={"outline"}
                            w="100%"
                            colorScheme="yellow"
                          >
                            <Grid w="100%" gridTemplateColumns={"1fr 3fr 1fr"}>
                              <GridItem
                                display={"flex"}
                                justifyContent={"flex-start"}
                                alignItems={"center"}
                              >
                                <Icon as={FaFeatherAlt} fontSize={14} />
                              </GridItem>
                              <GridItem
                                display={"flex"}
                                justifyContent={"center"}
                              >
                                <Text>질문 성찰 하러가기</Text>
                              </GridItem>
                              <GridItem
                                display={"flex"}
                                justifyContent={"flex-end"}
                                alignItems={"center"}
                              >
                                <Icon fontSize={20} as={FaAngleRight} />
                              </GridItem>
                            </Grid>
                          </Button>
                        </Link>
                      </Box>
                    ) : (
                      <UnorderedList>
                        <VStack w="100%" alignItems={"flex-start"}>
                          {quesAnswerData?.pages.map((page, index) => (
                            <VStack
                              key={index}
                              w="100%"
                              alignItems={"flex-start"}
                            >
                              {page.results.map((data, index) =>
                                data.sub_answer === "" ? null : (
                                  <Box
                                    mb="2"
                                    key={index}
                                    _hover={{ textDecoration: "underline" }}
                                  >
                                    <Link
                                      to={`/reflection/questions/${
                                        data.question_pk
                                      }/${
                                        data.type === "positive"
                                          ? "positive"
                                          : "negative"
                                      }/${data.pk}`}
                                    >
                                      <ListItem>{data.sub_question}</ListItem>
                                      <Text fontWeight={"700"}>
                                        → {data.sub_answer}
                                      </Text>
                                    </Link>
                                  </Box>
                                )
                              )}
                            </VStack>
                          ))}
                        </VStack>
                      </UnorderedList>
                    )}
                  </>
                )}
              </CardBody>
            </Collapse>
            {hasQuesAnswerNextPage ? (
              <CardFooter>
                <VStack w="100%">
                  <Button
                    mt="3"
                    spinner={
                      <BeatLoader
                        color={colorMode === "light" ? "black" : "white"}
                        size="8px"
                      />
                    }
                    onClick={() => {
                      fetchQuesAnswerNextPage();
                    }}
                    isLoading={isQuesAnswerFetchingNextPage}
                  >
                    더 보기
                  </Button>
                </VStack>
              </CardFooter>
            ) : null}
          </Card>
          <Card w="100%">
            <CardHeader>
              <Grid gridTemplateColumns={"1fr 1fr 1fr"}>
                <GridItem alignContent={"center"} fontSize={"xl"}>
                  <Flex h="100%">
                    <Flex
                      h="100%"
                      cursor="pointer"
                      onClick={togglePosExpCollapse}
                      alignItems={"center"}
                      justifyContent={"center"}
                    >
                      <Icon as={isPosExpCollapsed ? FaAngleDown : FaAngleUp} />
                    </Flex>
                  </Flex>
                </GridItem>
                <VStack>
                  <Text fontSize={"xl"}>긍정경험</Text>
                </VStack>
                <Box></Box>
              </Grid>
            </CardHeader>
            <Collapse in={!isPosExpCollapsed} animateOpacity unmountOnExit>
              <CardBody>
                <Divider mb="3" />
                {isPosExpLoading ? (
                  <UnorderedList>
                    <VStack w="100%" alignItems={"flex-start"}>
                      <Skeleton h="20px" w="250px" borderRadius={"lg"} />
                      <Skeleton h="20px" w="250px" borderRadius={"lg"} />
                      <Skeleton h="20px" w="250px" borderRadius={"lg"} />
                      <Skeleton h="20px" w="250px" borderRadius={"lg"} />
                      <Skeleton h="20px" w="250px" borderRadius={"lg"} />
                      <Skeleton h="20px" w="250px" borderRadius={"lg"} />
                      <Skeleton h="20px" w="250px" borderRadius={"lg"} />
                      <Skeleton h="20px" w="250px" borderRadius={"lg"} />
                      <Skeleton h="20px" w="250px" borderRadius={"lg"} />
                      <Skeleton h="20px" w="250px" borderRadius={"lg"} />
                    </VStack>
                  </UnorderedList>
                ) : (
                  <>
                    {PosExpData?.pages[0].count === 0 ? (
                      <Box>
                        <Flex mb="3" w="100%" justifyContent={"center"}>
                          아직 성찰이 없습니다.
                        </Flex>
                        <Link
                          style={{ width: "100%" }}
                          to={"/reflection/questions/select"}
                        >
                          <Button
                            variant={"outline"}
                            w="100%"
                            colorScheme="green"
                          >
                            <Grid w="100%" gridTemplateColumns={"1fr 3fr 1fr"}>
                              <GridItem
                                display={"flex"}
                                justifyContent={"flex-start"}
                                alignItems={"center"}
                              >
                                <Icon as={FaLeaf} fontSize={14} />
                              </GridItem>
                              <GridItem
                                display={"flex"}
                                justifyContent={"center"}
                              >
                                <Text>긍정경험 성찰 하러가기</Text>
                              </GridItem>
                              <GridItem
                                display={"flex"}
                                justifyContent={"flex-end"}
                                alignItems={"center"}
                              >
                                <Icon fontSize={20} as={FaAngleRight} />
                              </GridItem>
                            </Grid>
                          </Button>
                        </Link>
                      </Box>
                    ) : (
                      <UnorderedList>
                        <VStack w="100%" alignItems={"flex-start"}>
                          {PosExpData?.pages.map((page, index) => (
                            <VStack
                              key={index}
                              w="100%"
                              alignItems={"flex-start"}
                            >
                              {page.results.map((data, index) =>
                                data.positive_experience === "" ? null : (
                                  <ListItem key={index}>
                                    <Link
                                      to={`/reflection/experiences/positive/${data.pk}`}
                                    >
                                      <Text
                                        fontWeight={"700"}
                                        _hover={{ textDecoration: "underline" }}
                                      >
                                        {data.positive_experience}
                                      </Text>
                                    </Link>
                                  </ListItem>
                                )
                              )}
                            </VStack>
                          ))}
                        </VStack>
                      </UnorderedList>
                    )}
                  </>
                )}
              </CardBody>
            </Collapse>
            {hasPosExpNextPage ? (
              <CardFooter>
                <VStack w="100%">
                  <Button
                    mt="3"
                    spinner={
                      <BeatLoader
                        color={colorMode === "light" ? "black" : "white"}
                        size="8px"
                      />
                    }
                    onClick={() => {
                      fetchPosExpNextPage();
                    }}
                    isLoading={isPosExpFetchingNextPage}
                  >
                    더 보기
                  </Button>
                </VStack>
              </CardFooter>
            ) : null}
          </Card>
          <Card w="100%">
            <CardHeader>
              <Grid gridTemplateColumns={"1fr 1fr 1fr"}>
                <GridItem alignContent={"center"} fontSize={"xl"}>
                  <Flex h="100%">
                    <Flex
                      h="100%"
                      cursor="pointer"
                      onClick={toggleNegExpCollapse}
                      alignItems={"center"}
                      justifyContent={"center"}
                    >
                      <Icon as={isNegExpCollapsed ? FaAngleDown : FaAngleUp} />
                    </Flex>
                  </Flex>
                </GridItem>
                <VStack>
                  <Text fontSize={"xl"}>부정경험</Text>
                </VStack>
                <Box></Box>
              </Grid>
            </CardHeader>
            <Collapse in={!isNegExpCollapsed} animateOpacity unmountOnExit>
              <CardBody>
                <Divider mb="3" />
                {isNegExpLoading ? (
                  <UnorderedList>
                    <VStack w="100%" alignItems={"flex-start"}>
                      <Skeleton h="20px" w="250px" borderRadius={"lg"} />
                      <Skeleton h="20px" w="250px" borderRadius={"lg"} />
                      <Skeleton h="20px" w="250px" borderRadius={"lg"} />
                      <Skeleton h="20px" w="250px" borderRadius={"lg"} />
                      <Skeleton h="20px" w="250px" borderRadius={"lg"} />
                      <Skeleton h="20px" w="250px" borderRadius={"lg"} />
                      <Skeleton h="20px" w="250px" borderRadius={"lg"} />
                      <Skeleton h="20px" w="250px" borderRadius={"lg"} />
                      <Skeleton h="20px" w="250px" borderRadius={"lg"} />
                      <Skeleton h="20px" w="250px" borderRadius={"lg"} />
                    </VStack>
                  </UnorderedList>
                ) : (
                  <>
                    {NegExpData?.pages[0].count === 0 ? (
                      <Box>
                        <Flex mb="3" w="100%" justifyContent={"center"}>
                          아직 성찰이 없습니다.
                        </Flex>
                        <Link
                          style={{ width: "100%" }}
                          to={"/reflection/questions/select"}
                        >
                          <Button
                            variant={"outline"}
                            w="100%"
                            colorScheme="blue"
                          >
                            <Grid w="100%" gridTemplateColumns={"1fr 3fr 1fr"}>
                              <GridItem
                                display={"flex"}
                                justifyContent={"flex-start"}
                                alignItems={"center"}
                              >
                                <Icon as={FaCloud} fontSize={14} />
                              </GridItem>
                              <GridItem
                                display={"flex"}
                                justifyContent={"center"}
                              >
                                <Text>부정경험 성찰 하러가기</Text>
                              </GridItem>
                              <GridItem
                                display={"flex"}
                                justifyContent={"flex-end"}
                                alignItems={"center"}
                              >
                                <Icon fontSize={20} as={FaAngleRight} />
                              </GridItem>
                            </Grid>
                          </Button>
                        </Link>
                      </Box>
                    ) : (
                      <UnorderedList>
                        <VStack w="100%" alignItems={"flex-start"}>
                          {NegExpData?.pages.map((page, index) => (
                            <VStack
                              key={index}
                              w="100%"
                              alignItems={"flex-start"}
                            >
                              {page.results.map((data, index) =>
                                data.negative_experience === "" ? null : (
                                  <ListItem key={index}>
                                    <Link
                                      to={`/reflection/experiences/negative/${data.pk}`}
                                    >
                                      <Text
                                        fontWeight={"700"}
                                        _hover={{ textDecoration: "underline" }}
                                      >
                                        {data.negative_experience}
                                      </Text>
                                    </Link>
                                  </ListItem>
                                )
                              )}
                            </VStack>
                          ))}
                        </VStack>
                      </UnorderedList>
                    )}
                  </>
                )}
              </CardBody>
            </Collapse>
            {hasNegExpNextPage ? (
              <CardFooter>
                <VStack w="100%">
                  <Button
                    mt="3"
                    spinner={
                      <BeatLoader
                        color={colorMode === "light" ? "black" : "white"}
                        size="8px"
                      />
                    }
                    onClick={() => {
                      fetchNegExpNextPage();
                    }}
                    isLoading={isNegExpFetchingNextPage}
                  >
                    더 보기
                  </Button>
                </VStack>
              </CardFooter>
            ) : null}
          </Card>
        </VStack>
      </Container>
    </ProtectedPage>
  );
}
