import {
  Button,
  Card,
  CardBody,
  CardFooter,
  Checkbox,
  Divider,
  Flex,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Grid,
  Input,
  Select,
  Text,
  VStack,
} from "@chakra-ui/react";
import { useMutation } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import {
  editProfile,
  IEditProfileError,
  IEditProfileSuccess,
  IEditProfileVariables,
} from "../routes/api";
import { IUser } from "../lib/useUser";
import NotificationSetting from "./NotificationSetting";
import { isSupported } from "firebase/messaging";

interface IForm {
  name: string;
  year: string;
  month: string;
  day: string;
  gender: string;
  isMarketingAgreed: boolean;
}

interface IEditProfileCardProps {
  user: IUser;
}

export default function EditProfileCard({ user }: IEditProfileCardProps) {
  const [birthdateErrorMessage, setBirthdateErrorMessage] = useState("");
  const [fcmSupported, setFcmSupported] = useState(false);
  const {
    register,
    reset,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm<IForm>();

  const mutation = useMutation<
    IEditProfileSuccess,
    IEditProfileError,
    IEditProfileVariables
  >({
    mutationFn: editProfile,
  });

  useEffect(() => {
    if (user) {
      const splitedBirthDate = user.birth_date.split("-");
      reset({
        name: user.name,
        year: splitedBirthDate[0],
        month: splitedBirthDate[1],
        day: splitedBirthDate[2],
        gender: user.gender,
        isMarketingAgreed: user.is_marketing_agreed,
      });
    }
    const checkSupport = async () => {
      const supported = await isSupported();
      setFcmSupported(supported);
    };
    checkSupport();
  }, [user, reset]);

  const validateDate = (year: string, month: string, day: string) => {
    const yearPattern = /^\d{4}$/;
    const monthPattern = /^\d{1,2}$/;
    const dayPattern = /^\d{1,2}$/;

    if (!yearPattern.test(year)) {
      setBirthdateErrorMessage("출생 연도는 4자리 숫자로 입력해주세요.");
      return false;
    }

    if (!monthPattern.test(month)) {
      setBirthdateErrorMessage(
        "출생 월은 1부터 12 사이의 숫자로 입력해주세요."
      );
      return false;
    }

    if (!dayPattern.test(day)) {
      setBirthdateErrorMessage(
        "출생 일은 1부터 31 사이의 숫자로 입력해주세요."
      );
      return false;
    }

    const date = new Date(parseInt(year), parseInt(month) - 1, parseInt(day));
    const now = new Date();

    if (
      isNaN(date.getTime()) ||
      date.getFullYear() < 1900 ||
      date.getFullYear() > now.getFullYear() ||
      date.getFullYear() !== parseInt(year) ||
      date.getMonth() + 1 !== parseInt(month) ||
      date.getDate() !== parseInt(day)
    ) {
      setBirthdateErrorMessage("유효한 생년월일을 입력해주세요.");
      return false;
    } else {
      setBirthdateErrorMessage("");
      return true;
    }
  };

  const onSubmit = ({
    name,
    year,
    month,
    day,
    gender,
    isMarketingAgreed,
  }: IForm) => {
    if (!validateDate(year, month, day)) {
      return;
    }

    const formattedDate = `${year}-${month.padStart(2, "0")}-${day.padStart(
      2,
      "0"
    )}`;

    mutation.mutate({
      name,
      gender,
      is_marketing_agreed: isMarketingAgreed,
      birth_date: formattedDate,
    });
  };

  const validateNameSpace = (name: string) => {
    if (name.trim() === "") {
      return "닉네임을 입력해주세요.";
    }
    if (name.trim() !== name) {
      return "앞 뒤 공백은 허용되지 않습니다.";
    }
  };

  const validateDateSpace = (value: string) => {
    const spaceRemovedValue = value.replace(/\s+/g, "");
    if (spaceRemovedValue === "") {
      return "띄어쓰기는 허용되지 않습니다.";
    }
    if (spaceRemovedValue !== value) {
      return "띄어쓰기는 허용되지 않습니다.";
    }
  };

  return (
    <>
      <Flex w="100%" justifyContent={"center"}>
        <Text fontSize={24}>프로필</Text>
      </Flex>
      {fcmSupported && <NotificationSetting />}
      <Card mt={"2"} as="form" onSubmit={handleSubmit(onSubmit)}>
        <CardBody>
          <VStack>
            <FormControl>
              <FormLabel>이메일</FormLabel>
              <Text>{user?.email}</Text>
            </FormControl>
            <Divider />
            <FormControl>
              <FormLabel>아이디</FormLabel>
              {user?.username}
            </FormControl>
            <Divider />
            <FormControl isInvalid={Boolean(errors.name?.message)}>
              <FormLabel>닉네임</FormLabel>
              <Input
                {...register("name", {
                  required: "닉네임을 입력해주세요.",
                  maxLength: 20,
                  validate: validateNameSpace,
                  onBlur: (e: React.FocusEvent<HTMLInputElement>) =>
                    setValue("name", e.target.value.trim()),
                })}
                maxLength={20}
              />
              {errors.name?.message && (
                <FormErrorMessage>{errors.name.message}</FormErrorMessage>
              )}
              {errors.name?.type === "maxLength" ? (
                <Text color="red.400">닉네임 20자 이하여야 합니다.</Text>
              ) : null}
            </FormControl>
            <FormControl
              isInvalid={
                Boolean(errors.year?.message) ||
                Boolean(errors.month?.message) ||
                Boolean(errors.day?.message)
              }
            >
              <FormLabel>생년월일</FormLabel>
              <Grid gridTemplateColumns={"1fr 1fr 1fr"} gap="1" w="100%">
                <Input
                  id="year"
                  {...register("year", {
                    required: "전체 생년월일을 입력해주세요.",
                    validate: validateDateSpace,
                    onChange: (e: React.ChangeEvent<HTMLInputElement>) =>
                      setValue("year", e.target.value.replace(/\s+/g, "")),
                  })}
                  placeholder="연"
                />
                <Input
                  id="month"
                  {...register("month", {
                    required: "전체 생년월일을 입력해주세요.",
                    validate: validateDateSpace,
                    onChange: (e: React.ChangeEvent<HTMLInputElement>) =>
                      setValue("month", e.target.value.replace(/\s+/g, "")),
                  })}
                  placeholder="월"
                />
                <Input
                  id="day"
                  {...register("day", {
                    required: "전체 생년월일을 입력해주세요.",
                    validate: validateDateSpace,
                    onChange: (e: React.ChangeEvent<HTMLInputElement>) =>
                      setValue("day", e.target.value.replace(/\s+/g, "")),
                  })}
                  placeholder="일"
                />
              </Grid>
              {errors.year?.message ||
              errors.month?.message ||
              errors.day?.message ? (
                <FormErrorMessage>
                  전체 생년월일을 입력해주세요.
                </FormErrorMessage>
              ) : null}
              {birthdateErrorMessage !== "" && (
                <FormHelperText color="red.300">
                  {birthdateErrorMessage}
                </FormHelperText>
              )}
            </FormControl>
            <FormControl isInvalid={Boolean(errors.gender?.message)}>
              <FormLabel>성별</FormLabel>
              <Select
                {...register("gender", {
                  required: "성별을 선택해주세요.",
                })}
              >
                <option value="male">남성</option>
                <option value="female">여성</option>
                <option value="unspecified">공개 안함</option>
              </Select>
              <FormErrorMessage>{errors.gender?.message}</FormErrorMessage>
            </FormControl>
            <FormControl mt="1">
              <Checkbox {...register("isMarketingAgreed")}>
                마케팅 수신 동의
              </Checkbox>
            </FormControl>
          </VStack>
        </CardBody>
        <CardFooter>
          <VStack w="100%">
            <Button
              colorScheme="blue"
              type="submit"
              w="100%"
              isLoading={mutation.isPending}
            >
              저장
            </Button>
            {(mutation.isSuccess || mutation.isError) && (
              <Card
                w="100%"
                color={
                  mutation.isSuccess
                    ? "green"
                    : mutation.isError
                    ? "red.400"
                    : undefined
                }
                bg="white"
              >
                <CardBody>
                  <VStack>
                    <Text>
                      {mutation.isSuccess
                        ? "수정이 완료되었습니다."
                        : mutation.isError
                        ? "문제가 발생했습니다. 다시 시도해주세요"
                        : null}
                    </Text>
                  </VStack>
                </CardBody>
              </Card>
            )}
          </VStack>
        </CardFooter>
      </Card>
    </>
  );
}
