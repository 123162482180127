import { Box, Text } from "@chakra-ui/react";

export default function NegativeEmotions() {
  return (
    <Box px="12" mt="3">
      <Text textAlign={"center"} lineHeight={2}>
        분노 적대감 증오 경멸 질투 삐침 두려움 불안 초조함 염려 절박함 공포 실망
        서운함 낙담 괴로움 자괴감 좌절 우울함 침울함 무기력함 피폐함 허무함
        자포자기 고립감 소외감 쓸쓸함 적막감 단절감 수치심 죄책감 후회 억울함
        초라함 실의 혼란 답답함 혼돈 무모함 황당 당황 비참함 불쾌감 무력감
        의기소침 피곤함 배신감 속상함 도망치고 싶음 절망
      </Text>
    </Box>
  );
}
