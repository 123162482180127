import { createBrowserRouter } from "react-router-dom";
import Root from "./components/Root";
import Home from "./routes/Home";
import Todolist from "./routes/Todolist";
import NotFound from "./routes/NotFound";
import EmailVerifiedSuccess from "./routes/EmailVerifiedSuccess";
import EmailVerifiedFailed from "./routes/EmailVerifiedFailed";
import SendVerificationEmail from "./routes/SendVerificationEmail";
import VerifyEmail from "./routes/VerifyEmail";
import Me from "./routes/Me";
import SignUp from "./routes/SignUp";
import NormalSignUp from "./routes/NormalSignUp";
import Login from "./routes/Login";
import KakaoConfirm from "./routes/KakaoConfirm";
import SocialSignUp from "./routes/SocialSignUp";
import Term from "./routes/Term";
import Privacy from "./routes/Privacy";
import TodolistGuide from "./routes/TodolistGuide";
import Experiences from "./routes/Experiences";
import UploadPosExperience from "./routes/UploadPosExperience";
import UploadNegExperience from "./routes/UploadNegExperience";
import PosExperienceDetail from "./routes/PosExperienceDetail";
import EditPosExperience from "./routes/EditPosExperience";
import MyReflection from "./routes/MyReflections";
import NegExperienceDetail from "./routes/NegExperienceDetail";
import EditNegExperience from "./routes/EditNegExperience";
import SelectQuestion from "./routes/SelectQuestion";
import UserQuestionReflections from "./routes/UserQuestionReflections";
import UploadPosQuestionReflection from "./routes/UploadPosQuestionReflection";
import UploadNegQuestionReflection from "./routes/UploadNegQuestionReflection";
import NegQuestionReflectionDetail from "./routes/NegQuestionReflectionDetail";
import PosQuestionReflectionDetail from "./routes/PosQuestionReflectionDetail";
import EditNegQuestionReflection from "./routes/EditNegQuestionReflection";
import EditPosQuestionReflection from "./routes/EditPosQuestionReflection";
import ReflectionGuide from "./routes/ReflectionGuide";
import FindUsername from "./routes/FindUsername";
import FindPassword from "./routes/FindPassword";
import ResetPassword from "./routes/ResetPassword";
import MyDiscoveries from "./routes/MyDiscoveries";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Root />,
    errorElement: <NotFound />,
    children: [
      {
        path: "",
        element: <Home />,
      },
      {
        path: "todolist",
        children: [
          { path: "", element: <Todolist /> },
          { path: "guide", element: <TodolistGuide /> },
        ],
      },
      {
        path: "reflection",
        children: [
          { path: "guide", element: <ReflectionGuide /> },
          { path: "questions", element: <UserQuestionReflections /> },
          { path: "questions/select", element: <SelectQuestion /> },
          {
            path: "questions/select/positive/:pk",
            element: <UploadPosQuestionReflection />,
          },
          {
            path: "questions/select/negative/:pk",
            element: <UploadNegQuestionReflection />,
          },
          {
            path: "questions/:questionPk/positive/:reflectionPk",
            element: <PosQuestionReflectionDetail />,
          },
          {
            path: "questions/:questionPk/negative/:reflectionPk",
            element: <NegQuestionReflectionDetail />,
          },
          {
            path: "questions/:questionPk/positive/:reflectionPk/edit",
            element: <EditPosQuestionReflection />,
          },
          {
            path: "questions/:questionPk/negative/:reflectionPk/edit",
            element: <EditNegQuestionReflection />,
          },
          { path: "experiences", element: <Experiences /> },
          {
            path: "experiences/positive/upload",
            element: <UploadPosExperience />,
          },
          {
            path: "experiences/positive/:pk",
            element: <PosExperienceDetail />,
          },
          {
            path: "experiences/positive/:pk/edit",
            element: <EditPosExperience />,
          },
          {
            path: "experiences/negative/upload",
            element: <UploadNegExperience />,
          },
          {
            path: "experiences/negative/:pk",
            element: <NegExperienceDetail />,
          },
          {
            path: "experiences/negative/:pk/edit",
            element: <EditNegExperience />,
          },
        ],
      },
      {
        path: "my",
        children: [
          { path: "discoveries", element: <MyDiscoveries /> },
          { path: "reflections", element: <MyReflection /> },
        ],
      },
      {
        path: "email-verified-success",
        element: <EmailVerifiedSuccess />,
      },
      {
        path: "email-verified-failed",
        element: <EmailVerifiedFailed />,
      },
      {
        path: "send-verification-email",
        element: <SendVerificationEmail />,
      },
      {
        path: "verify-email/:token",
        element: <VerifyEmail />,
      },
      {
        path: "me",
        element: <Me />,
      },
      {
        path: "sign-up",
        children: [
          { path: "", element: <SignUp /> },
          { path: "normal", element: <NormalSignUp /> },
          { path: "social", element: <SocialSignUp /> },
        ],
      },
      {
        path: "login",
        element: <Login />,
      },
      {
        path: "social",
        children: [
          {
            path: "kakao",
            element: <KakaoConfirm />,
          },
        ],
      },
      {
        path: "find",
        children: [
          { path: "username", element: <FindUsername /> },
          { path: "password", element: <FindPassword /> },
        ],
      },
      {
        path: "reset-password/:token",
        element: <ResetPassword />,
      },
      {
        path: "term",
        children: [
          {
            path: "",
            element: <Term />,
          },
          {
            path: "privacy",
            element: <Privacy />,
          },
        ],
      },
    ],
  },
]);

export default router;
