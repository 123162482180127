import { Box, Text } from "@chakra-ui/react";

export default function PositiveEmotions() {
  return (
    <Box px="12" mt="3">
      <Text textAlign={"center"} lineHeight={2} letterSpacing={0.5}>
        기쁨 만족 희열 즐거움 감동 흐뭇함 번뜩임 고마움 성취감 연대감 따뜻함
        편안함 황홀감 몰입감 충만감 전율 짜릿함 놀라움 신남 평온함 활기 열정
        재밌음 카타르시스 신선한 충격 해소됨 호기심 희망 경탄 설렘
      </Text>
    </Box>
  );
}
