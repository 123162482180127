import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  Card,
  CardBody,
  Container,
  Divider,
  Flex,
  Icon,
  Skeleton,
  Text,
  useColorModeValue,
  useDisclosure,
  VStack,
} from "@chakra-ui/react";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  deleteNegExpReflection,
  getNegativeExperience,
  IDeleteNegExpReflectionError,
  IDeleteNegExpReflectionVariables,
} from "./api";
import { useMutation, useQuery } from "@tanstack/react-query";
import React, { useEffect } from "react";
import { FaChevronLeft, FaPencilAlt, FaTrash } from "react-icons/fa";
import { INegativeExperienceReflection } from "../types";

export default function NegExperienceDetail() {
  const { pk } = useParams();
  const { isLoading, data: negExpReflection } = useQuery<
    INegativeExperienceReflection,
    Error
  >({
    queryKey: ["negativeExperience", pk],
    queryFn: getNegativeExperience,
  });

  const navigate = useNavigate();
  const mutation = useMutation<
    any,
    IDeleteNegExpReflectionError,
    IDeleteNegExpReflectionVariables
  >({
    mutationFn: deleteNegExpReflection,
    onSuccess: () => {
      navigate("/reflection/experiences");
    },
  });
  const highlightColor = useColorModeValue("yellow.100", "green.800");
  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = React.useRef(null);

  const handleDelete = () => {
    if (pk) {
      mutation.mutate({ NegExpReflectionPk: parseInt(pk) });
    }
  };

  return (
    <Container mb="10">
      <Flex>
        <Link to={`/reflection/experiences`}>
          <Flex py="5" px="1">
            <Icon as={FaChevronLeft} />
          </Flex>
        </Link>
      </Flex>
      {isLoading ? (
        <VStack>
          <Skeleton w="80%" h="30px" borderRadius={"lg"} />
          <Skeleton w="100%" h="600px" borderRadius={"lg"} />
          <Skeleton w="100%" h="400px" borderRadius={"lg"} />
        </VStack>
      ) : (
        <VStack>
          <Text fontSize={"22"} textAlign={"center"} fontWeight={700}>
            {negExpReflection?.title}
          </Text>
          <Card w="100%">
            <CardBody>
              <Text textAlign={"center"} fontSize={"20"} fontWeight={900}>
                부정경험 성찰
              </Text>
              <Divider my="3" />
              <Text>1. 안좋았던 경험을 하나 작성해주세요.</Text>
              <Text mt="2" fontWeight={700}>
                → {negExpReflection?.negative_experience}
              </Text>
              <Divider my="3" />
              <Text>2. 그 경험에서 구체적으로 어떤 감정을 느꼈나요?</Text>
              <Text mt="2" fontWeight={700}>
                → {negExpReflection?.specific_emotion}
              </Text>
              <Divider my="3" />
              <Text>3. 경험의 어떤 부분이 그 감정을 일으켰나요?</Text>
              <Text mt="2" fontWeight={700}>
                → {negExpReflection?.situation_analysis}
              </Text>
              <Divider my="3" />
              <Text>
                4. 그 부분을 부정가치로 표현한다면 어떤 단어가 적절할까요?
              </Text>
              <Text mt="2" fontWeight={700}>
                → {negExpReflection?.negative_value}
              </Text>
              <Divider my="3" />
              <Text>
                5. 비슷한 부정가치와 감정을 느낀 다른 경험들이 있나요?
              </Text>
              <Text mt="2" fontWeight={700}>
                → {negExpReflection?.similar_experience}
              </Text>
              <Divider my="3" />
              <Text>
                6. 여기서 알 수 있는 나에게 의미있는 긍정가치는 무엇인가요?
              </Text>
              <Text mt="2" fontWeight={700}>
                → {negExpReflection?.positive_value}
              </Text>
              <Divider my="3" />
              <Text>
                7. 그 긍정가치를 실천할 수 있는 활동들엔 어떤 게 있나요?
              </Text>
              <Text mt="2" fontWeight={700}>
                → {negExpReflection?.related_activity}
              </Text>
            </CardBody>
          </Card>
          <Card w="100%">
            <CardBody>
              <Text textAlign={"center"} fontSize={"20"} fontWeight={900}>
                성찰 키워드
              </Text>
              <Divider my="3" />
              <Text>나에게 해로운 부정가치</Text>
              <Text fontWeight={700}>
                → "{negExpReflection?.reflection_keyword.negative_value}"
              </Text>
              <Divider my="3" />
              <Text mt="3">부정가치에 느끼는 감정</Text>
              <Text fontWeight={700}>
                → "{negExpReflection?.reflection_keyword.negative_emotion}"
              </Text>
              <Divider my="3" />
              <Text mt="3">나에게 의미있는 긍정가치</Text>
              <Text fontWeight={700}>
                → "{negExpReflection?.reflection_keyword.positive_value}"
              </Text>
              <Divider my="3" />
              <Text mt="3">긍정가치를 실천할 수 있는 활동들</Text>
              <Text fontWeight={700}>
                → "{negExpReflection?.reflection_keyword.activity}"
              </Text>
            </CardBody>
          </Card>
          <Card w="100%">
            <CardBody>
              <VStack>
                <Text fontSize={20} fontWeight={900} textAlign={"center"}>
                  나의 성찰
                </Text>
                <Divider />
                <Text textAlign={"center"} lineHeight={2}>
                  나는{" "}
                  <Text
                    as="span"
                    my="3"
                    fontSize={18}
                    fontWeight={"700"}
                    bg={highlightColor}
                  >
                    {negExpReflection?.reflection_keyword.negative_value}
                  </Text>{" "}
                  에{" "}
                  <Text
                    as="span"
                    my="3"
                    fontSize={18}
                    fontWeight={"700"}
                    bg={highlightColor}
                  >
                    {negExpReflection?.reflection_keyword.negative_emotion}
                  </Text>{" "}
                  을(를) 느낀다.
                </Text>
              </VStack>
            </CardBody>
          </Card>
          <Card w="100%">
            <CardBody>
              <VStack>
                <Text fontSize={20} fontWeight={900} textAlign={"center"}>
                  나의 추구
                </Text>
                <Divider />
                <Text textAlign={"center"} lineHeight={2}>
                  나는{" "}
                  <Text
                    as="span"
                    my="3"
                    fontSize={18}
                    fontWeight={"700"}
                    bg={highlightColor}
                  >
                    {negExpReflection?.reflection_keyword.activity}
                  </Text>{" "}
                  을(를) 통해{" "}
                  <Text
                    as="span"
                    my="3"
                    fontSize={18}
                    fontWeight={"700"}
                    bg={highlightColor}
                  >
                    {negExpReflection?.reflection_keyword.positive_value}
                  </Text>{" "}
                  을(를) 추구할 것이다.
                </Text>
              </VStack>
            </CardBody>
          </Card>
          <Link to="edit" style={{ width: "100%" }}>
            <Button w="100%" colorScheme="green">
              <Icon as={FaPencilAlt} />
            </Button>
          </Link>
          <Divider my="5" />
          <Button w="100%" colorScheme="red" onClick={onOpen}>
            <Icon as={FaTrash} />
          </Button>
          <AlertDialog
            isCentered
            isOpen={isOpen}
            leastDestructiveRef={cancelRef}
            onClose={onClose}
          >
            <AlertDialogOverlay>
              <AlertDialogContent>
                <AlertDialogHeader fontSize="lg" fontWeight="bold">
                  "{negExpReflection?.title}" 삭제
                </AlertDialogHeader>
                <AlertDialogBody>
                  <Text>정말로 성찰을 삭제하시겠습니까?</Text>
                </AlertDialogBody>
                <AlertDialogFooter>
                  <Button ref={cancelRef} onClick={onClose}>
                    아니요. 돌려주세요.
                  </Button>
                  <Button
                    isLoading={mutation.isPending}
                    colorScheme="red"
                    onClick={handleDelete}
                    ml={3}
                  >
                    네. 확신합니다.
                  </Button>
                </AlertDialogFooter>
              </AlertDialogContent>
            </AlertDialogOverlay>
          </AlertDialog>
        </VStack>
      )}
    </Container>
  );
}
