import { Box, Text } from "@chakra-ui/react";

export default function PositiveValues() {
  return (
    <Box px="12" mt="3">
      <Text textAlign={"center"} lineHeight={2} letterSpacing={0.5}>
        사랑 평화 화합 조화 연결 정의 평등 관용 존중 공존 창조 혁신 탐구 탐험
        창의성 성장 변화 도전 강인함 유연함 온전함 정교함 통찰 지혜 독창성 신뢰
        연대 협동 헌신 희생 겸손 정직 책임감 용서 자기수양 인내 강인함 배려 공감
        친절 감화 인정 나눔 감사 자유 자율성 평정심 안정 참신함
      </Text>
    </Box>
  );
}
