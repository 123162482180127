import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  Card,
  CardBody,
  Container,
  Flex,
  Icon,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Skeleton,
  Text,
  Textarea,
  useDisclosure,
  useToast,
  VStack,
} from "@chakra-ui/react";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  getReflectionQuestion,
  IUploadPosQuestionReflectionError,
  IUploadPosQuestionReflectionSuccess,
  IUploadPosQuestionReflectionVariables,
  uploadPosQuestionReflection,
} from "./api";
import React, { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { FaChevronLeft } from "react-icons/fa";
import ProtectedPage from "../components/ProtectedPage";

interface ISubQuestion {
  pk: number;
  text: string;
  order: number;
  is_positive_emotion: boolean;
  is_positive_value: boolean;
  is_negative_emotion: boolean;
  is_negative_value: boolean;
}

interface IQuestionDetail {
  title: string;
  sub_questions: ISubQuestion[];
  type: "positive" | "negative";
}

interface IForm {
  [key: `subAnswer${number}`]: string;
  keywordPositiveValue: string;
  keywordPositiveEmotion: string;
  keywordActivity: string;
  title: string;
}

interface ISubmit {
  [key: `subAnswer${number}`]: string;
  title: string;
}

export default function UploadPosQuestionReflection() {
  const [isSave, setIsSave] = useState(true);
  const { register, watch, handleSubmit } = useForm<ISubmit>();
  const { pk } = useParams();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const containerRef = useRef(null);
  const { isLoading, data: posReflectionQuestion } = useQuery<IQuestionDetail>({
    queryKey: ["posReflectionQuestion", pk],
    queryFn: getReflectionQuestion,
  });
  const navigate = useNavigate();
  const toast = useToast();
  const mutation = useMutation<
    IUploadPosQuestionReflectionSuccess,
    IUploadPosQuestionReflectionError,
    IUploadPosQuestionReflectionVariables
  >({
    mutationFn: uploadPosQuestionReflection,
    onSuccess: (data) => {
      toast({
        title: `성찰이 업로드 되었습니다!`,
        status: "success",
        position: "top",
        isClosable: true,
        duration: 3000,
      });

      if (isSave) {
        navigate("/reflection/questions/select");
      } else {
        navigate(
          `/reflection/questions/${pk}/${posReflectionQuestion?.type}/${data.pk}/edit`,
          { state: { isFromUpload: true } }
        );
      }
    },
    onError: (error) => console.log(error),
  });

  const location = useLocation();
  const pathType = location.pathname.split("/")[4];
  useEffect(() => {
    if (posReflectionQuestion) {
      if (posReflectionQuestion.type !== pathType) {
        navigate("/reflection/questions/select");
      }
    }
  }, [posReflectionQuestion, navigate, pathType]);

  const onSubmit = (submitData: ISubmit) => {
    if (pk && posReflectionQuestion) {
      let formData: IForm;
      formData = {
        ...submitData,
        keywordPositiveValue: "",
        keywordPositiveEmotion: "",
        keywordActivity: "",
      };
      const otherSubQuestions = posReflectionQuestion.sub_questions.filter(
        (sub_question, index) => index !== 0
      );
      otherSubQuestions.forEach((subQuestion) => {
        formData = { ...formData, [`subAnswer${subQuestion.pk}`]: "" };
      });
      mutation.mutate({ formData, pk: parseInt(pk) });
    }
  };

  const {
    isOpen: isCancelAlertOpen,
    onOpen: onCancelAlertOpen,
    onClose: onCancelAlertClose,
  } = useDisclosure();
  const cancelRef = React.useRef(null);

  const formValue = watch();

  const handleCancelAlertOpen = () => {
    if (posReflectionQuestion) {
      if (
        formValue["title"] ||
        formValue[`subAnswer${posReflectionQuestion?.sub_questions[0].pk}`]
      ) {
        onCancelAlertOpen();
      } else {
        navigate(`/reflection/questions/select`);
      }
    }
  };

  const handleCancel = () => {
    onCancelAlertClose();
    navigate(`/reflection/questions/select`);
  };

  return (
    <ProtectedPage>
      <Container>
        <Flex>
          <Flex
            py="5"
            px="1"
            onClick={handleCancelAlertOpen}
            _hover={{ cursor: "pointer" }}
          >
            <Icon as={FaChevronLeft} />
          </Flex>
        </Flex>
        <AlertDialog
          isCentered
          isOpen={isCancelAlertOpen}
          leastDestructiveRef={cancelRef}
          onClose={onCancelAlertClose}
        >
          <AlertDialogOverlay>
            <AlertDialogContent>
              <AlertDialogHeader fontSize="lg" fontWeight="bold">
                질문성찰 닫기
              </AlertDialogHeader>
              <AlertDialogBody>저장하지 않고 닫으시겠습니까?</AlertDialogBody>
              <AlertDialogFooter>
                <Button ref={cancelRef} onClick={onCancelAlertClose}>
                  취소
                </Button>
                <Button colorScheme="red" onClick={handleCancel} ml={3}>
                  닫기
                </Button>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialogOverlay>
        </AlertDialog>
        {isLoading ? (
          <>
            <VStack spacing={"3"}>
              <Skeleton w="100%" h="300px" borderRadius={"lg"} />
              <Skeleton w="100%" h="40px" borderRadius={"lg"} />
            </VStack>
          </>
        ) : (
          <VStack>
            {posReflectionQuestion ? (
              <VStack
                w="100%"
                as="form"
                onSubmit={handleSubmit(onSubmit)}
                ref={containerRef}
              >
                <Card w="100%">
                  <CardBody px="3">
                    <VStack w="100%">
                      <Text
                        textAlign="center"
                        fontSize={18}
                        fontWeight={700}
                        wordBreak="keep-all"
                        whiteSpace="normal"
                      >
                        {posReflectionQuestion.sub_questions[0].text}
                      </Text>
                      <Textarea
                        h="200px"
                        w="100%"
                        resize="vertical"
                        {...register(
                          `subAnswer${posReflectionQuestion.sub_questions[0].pk}`
                        )}
                      />
                    </VStack>
                  </CardBody>
                </Card>
                <Button w="100%" fontSize="16" onClick={onOpen} type="button">
                  저장
                </Button>
                <Modal
                  isOpen={isOpen}
                  onClose={onClose}
                  isCentered
                  portalProps={{ containerRef }}
                >
                  <ModalOverlay />
                  <ModalContent mx="4">
                    <ModalHeader></ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                      <Text textAlign={"center"} mb="3">
                        이번 성찰의 제목을 지어주세요!
                      </Text>
                      <Input {...register("title")} />
                    </ModalBody>
                    <ModalFooter>
                      <VStack w="100%">
                        <Button
                          w="100%"
                          fontSize="16"
                          type="submit"
                          isLoading={mutation.isPending}
                          colorScheme="blue"
                        >
                          저장 →
                        </Button>
                        <Button
                          w="100%"
                          fontSize="16"
                          type="submit"
                          isLoading={mutation.isPending}
                          colorScheme="orange"
                          onClick={() => setIsSave(false)}
                        >
                          더 깊이 성찰하기 →
                        </Button>
                      </VStack>
                    </ModalFooter>
                  </ModalContent>
                </Modal>
              </VStack>
            ) : null}
          </VStack>
        )}
      </Container>
    </ProtectedPage>
  );
}
