import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Box,
  Button,
  Card,
  CardBody,
  Collapse,
  Container,
  Divider,
  Flex,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  Icon,
  Input,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  Textarea,
  useColorModeValue,
  useDisclosure,
  useToast,
  VStack,
} from "@chakra-ui/react";
import { useMutation, useQuery } from "@tanstack/react-query";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import {
  FaArrowLeft,
  FaArrowRight,
  FaChevronDown,
  FaChevronLeft,
  FaChevronUp,
} from "react-icons/fa";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import {
  editPosExpReflection,
  getPositiveExperience,
  IEditPosExpReflectionError,
  IEditPosExpReflectionSuccess,
  IEditPosExpReflectionVariables,
} from "./api";
import { IPositiveExperienceReflection } from "../types";
import PositiveEmotions from "../components/PositiveEmotions";
import PositiveValues from "../components/PositiveValues";

interface IForm {
  title: string;
  positiveExperience: string;
  specificEmotion: string;
  situationAnalysis: string;
  coreValue: string;
  similarExperience: string;
  relatedActivity: string;
  keywordPositiveValue: string;
  keywordPositiveEmotion: string;
  keywordActivity: string;
}

export default function EditPosExperience() {
  const location = useLocation();
  const isFromUpload = location.state?.isFromUpload;
  const index = isFromUpload ? 1 : 0;
  const [tabIndex, setTabIndex] = useState(index);
  const [isCollapsed, setIsCollapsed] = useState(false);
  const { pk } = useParams();
  const { isLoading, data: positiveReflectionData } = useQuery<
    IPositiveExperienceReflection,
    Error
  >({
    queryKey: ["positiveExperiences", pk],
    queryFn: getPositiveExperience,
  });

  const { register, watch, handleSubmit, reset } = useForm<IForm>();

  useEffect(() => {
    if (positiveReflectionData) {
      reset({
        title: positiveReflectionData.title,
        positiveExperience: positiveReflectionData.positive_experience,
        specificEmotion: positiveReflectionData.specific_emotion,
        situationAnalysis: positiveReflectionData.situation_analysis,
        coreValue: positiveReflectionData.core_value,
        similarExperience: positiveReflectionData.similar_experience,
        relatedActivity: positiveReflectionData.related_activity,
        keywordPositiveValue:
          positiveReflectionData.reflection_keyword.positive_value,
        keywordPositiveEmotion:
          positiveReflectionData.reflection_keyword.positive_emotion,
        keywordActivity: positiveReflectionData.reflection_keyword.activity,
      });
    }
  }, [positiveReflectionData, reset]);

  const highlightColor = useColorModeValue("yellow.100", "green.800");
  const navigate = useNavigate();
  const toast = useToast();
  const mutation = useMutation<
    IEditPosExpReflectionSuccess,
    IEditPosExpReflectionError,
    IEditPosExpReflectionVariables
  >({
    mutationFn: editPosExpReflection,
    onSuccess: () => {
      toast({
        title: `수정이 완료되었습니다!`,
        status: "success",
        position: "top",
        isClosable: true,
        duration: 3000,
      });
      navigate(`/reflection/experiences/positive/${pk}`);
    },
    onError: (error) => console.log(error),
  });

  const inputPositiveValue = watch("keywordPositiveValue");
  const inputPositiveEmotion = watch("keywordPositiveEmotion");
  const inputActivity = watch("keywordActivity");

  const onSubmit = ({
    title,
    positiveExperience,
    specificEmotion,
    situationAnalysis,
    coreValue,
    similarExperience,
    relatedActivity,
    keywordPositiveValue,
    keywordPositiveEmotion,
    keywordActivity,
  }: IForm) => {
    if (pk) {
      mutation.mutate({
        pk: parseInt(pk),
        title,
        positive_experience: positiveExperience,
        specific_emotion: specificEmotion,
        situation_analysis: situationAnalysis,
        core_value: coreValue,
        similar_experience: similarExperience,
        related_activity: relatedActivity,
        reflection_keyword: {
          positive_value: keywordPositiveValue,
          positive_emotion: keywordPositiveEmotion,
          activity: keywordActivity,
        },
      });
    }
  };

  const {
    isOpen: isCancelAlertOpen,
    onOpen: onCancelAlertOpen,
    onClose: onCancelAlertClose,
  } = useDisclosure();
  const cancelRef = React.useRef(null);

  const handleCancel = () => {
    onCancelAlertClose();
    navigate(`/reflection/experiences/positive/${pk}`);
  };

  const positiveExperienceState = watch("positiveExperience");
  const specificEmotionState = watch("specificEmotion");
  const situationAnalysisState = watch("situationAnalysis");
  const coreValueState = watch("coreValue");
  const similarExperienceState = watch("similarExperience");
  const relatedActivityState = watch("relatedActivity");

  const toggleCollapse = () => {
    setIsCollapsed(!isCollapsed);
  };

  return (
    <Container>
      <Flex>
        <Flex
          py="5"
          px="1"
          onClick={onCancelAlertOpen}
          _hover={{ cursor: "pointer" }}
        >
          <Icon as={FaChevronLeft} />
        </Flex>
      </Flex>
      <AlertDialog
        isCentered
        isOpen={isCancelAlertOpen}
        leastDestructiveRef={cancelRef}
        onClose={onCancelAlertClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              {positiveReflectionData?.title} 닫기
            </AlertDialogHeader>
            <AlertDialogBody>저장하지 않고 닫으시겠습니까?</AlertDialogBody>
            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onCancelAlertClose}>
                취소
              </Button>
              <Button colorScheme="red" onClick={handleCancel} ml={3}>
                닫기
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
      <VStack>
        <Tabs
          index={tabIndex}
          isFitted
          w="100%"
          onChange={(index) => setTabIndex(index)}
        >
          <TabList>
            <Tab>1</Tab>
            <Tab>2</Tab>
            <Tab>3</Tab>
            <Tab>4</Tab>
            <Tab>5</Tab>
            <Tab>6</Tab>
            <Tab>7</Tab>
          </TabList>
          {isLoading ? (
            "로딩중.."
          ) : (
            <TabPanels as="form" onSubmit={handleSubmit(onSubmit)}>
              <TabPanel px="0">
                <VStack>
                  <Card w="100%">
                    <CardBody px="3">
                      <VStack>
                        <Text
                          fontSize={20}
                          fontWeight={700}
                          textAlign={"center"}
                        >
                          좋았던 경험을 하나 작성해주세요!
                        </Text>
                        <Textarea
                          h="200px"
                          resize="vertical"
                          placeholder="ex) 지하철에서 음악을 듣는데 좋았다."
                          {...register("positiveExperience")}
                        />
                      </VStack>
                    </CardBody>
                  </Card>
                  <Grid
                    pb="5"
                    gap={2}
                    w="100%"
                    gridTemplateColumns={"1fr 1fr 1fr"}
                  >
                    <GridItem></GridItem>
                    <GridItem>
                      <Button
                        type="submit"
                        isLoading={mutation.isPending}
                        w="100%"
                      >
                        저장
                      </Button>
                    </GridItem>
                    <GridItem w="100%">
                      <Button w="100%" onClick={() => setTabIndex(1)}>
                        <Icon as={FaArrowRight} />
                      </Button>
                    </GridItem>
                  </Grid>
                </VStack>
              </TabPanel>
              <TabPanel px="0">
                <VStack>
                  <Card w="100%">
                    <CardBody py="3">
                      <Collapse in={!isCollapsed} animateOpacity unmountOnExit>
                        <VStack alignItems={"flex-start"}>
                          <Box w="100%">
                            <Text>1. 좋았던 경험을 하나 작성해주세요!</Text>
                            <Text mt="1" fontWeight={700}>
                              → {positiveExperienceState}
                            </Text>
                            <Divider my="1" />
                          </Box>
                        </VStack>
                      </Collapse>
                      <VStack w="100%">
                        <Button variant={"ghost"} onClick={toggleCollapse}>
                          <Icon
                            as={isCollapsed ? FaChevronDown : FaChevronUp}
                          />
                        </Button>
                      </VStack>
                    </CardBody>
                  </Card>
                  <Card w="100%">
                    <CardBody px="3">
                      <VStack>
                        <Text
                          fontSize={20}
                          fontWeight={700}
                          textAlign={"center"}
                        >
                          그 경험에서 구체적으로 어떤 감정을 느꼈나요?
                        </Text>
                        <Textarea
                          h="200px"
                          resize="vertical"
                          placeholder="ex) 신남, 아련함, 충만감"
                          {...register("specificEmotion")}
                        />
                        <PositiveEmotions />
                      </VStack>
                    </CardBody>
                  </Card>
                  <Grid
                    pb="5"
                    gap={2}
                    w="100%"
                    gridTemplateColumns={"1fr 1fr 1fr"}
                  >
                    <GridItem>
                      <Button w="100%" onClick={() => setTabIndex(0)}>
                        <Icon as={FaArrowLeft} />
                      </Button>
                    </GridItem>
                    <GridItem>
                      <Button
                        type="submit"
                        isLoading={mutation.isPending}
                        w="100%"
                      >
                        저장
                      </Button>
                    </GridItem>
                    <GridItem w="100%">
                      <Button w="100%" onClick={() => setTabIndex(2)}>
                        <Icon as={FaArrowRight} />
                      </Button>
                    </GridItem>
                  </Grid>
                </VStack>
              </TabPanel>
              <TabPanel px="0">
                <VStack>
                  <Card w="100%">
                    <CardBody py="3">
                      <Collapse in={!isCollapsed} animateOpacity unmountOnExit>
                        <VStack alignItems={"flex-start"}>
                          <Box w="100%">
                            <Text>1. 좋았던 경험을 하나 작성해주세요!</Text>
                            <Text mt="1" fontWeight={700}>
                              → {positiveExperienceState}
                            </Text>
                            <Divider my="1" />
                          </Box>
                          <Box w="100%">
                            <Text>
                              2. 그 경험에서 구체적으로 어떤 감정을 느꼈나요?
                            </Text>
                            <Text mt="1" fontWeight={700}>
                              → {specificEmotionState}
                            </Text>
                            <Divider my="1" />
                          </Box>
                        </VStack>
                      </Collapse>
                      <VStack w="100%">
                        <Button variant={"ghost"} onClick={toggleCollapse}>
                          <Icon
                            as={isCollapsed ? FaChevronDown : FaChevronUp}
                          />
                        </Button>
                      </VStack>
                    </CardBody>
                  </Card>
                  <Card w="100%">
                    <CardBody px="3">
                      <VStack>
                        <Box>
                          <Text
                            fontSize={20}
                            fontWeight={700}
                            textAlign={"center"}
                          >
                            경험의 어떤 부분이 그 감정을 일으켰나요?
                          </Text>
                        </Box>
                        <Textarea
                          h="200px"
                          resize="vertical"
                          placeholder="ex) 음악의 멜로디와 가사"
                          {...register("situationAnalysis")}
                        />
                        <Text textAlign={"center"}>
                          Tip: 경험에 '왜?' 라는 질문을 거듭해보면서 그 감정이
                          든 이유를 고민해보세요!
                        </Text>
                      </VStack>
                    </CardBody>
                  </Card>
                  <Grid
                    pb="5"
                    gap={2}
                    w="100%"
                    gridTemplateColumns={"1fr 1fr 1fr"}
                  >
                    <GridItem>
                      <Button w="100%" onClick={() => setTabIndex(1)}>
                        <Icon as={FaArrowLeft} />
                      </Button>
                    </GridItem>
                    <GridItem>
                      <Button
                        type="submit"
                        isLoading={mutation.isPending}
                        w="100%"
                      >
                        저장
                      </Button>
                    </GridItem>
                    <GridItem w="100%">
                      <Button w="100%" onClick={() => setTabIndex(3)}>
                        <Icon as={FaArrowRight} />
                      </Button>
                    </GridItem>
                  </Grid>
                </VStack>
              </TabPanel>
              <TabPanel px="0">
                <VStack>
                  <Card w="100%">
                    <CardBody py="3">
                      <Collapse in={!isCollapsed} animateOpacity unmountOnExit>
                        <VStack alignItems={"flex-start"}>
                          <Box w="100%">
                            <Text>1. 좋았던 경험을 하나 작성해주세요!</Text>
                            <Text mt="1" fontWeight={700}>
                              → {positiveExperienceState}
                            </Text>
                            <Divider my="1" />
                          </Box>
                          <Box w="100%">
                            <Text>
                              2. 그 경험에서 구체적으로 어떤 감정을 느꼈나요?
                            </Text>
                            <Text mt="1" fontWeight={700}>
                              → {specificEmotionState}
                            </Text>
                            <Divider my="1" />
                          </Box>
                          <Box w="100%">
                            <Text>
                              3. 경험의 어떤 부분이 그 감정을 일으켰나요?
                            </Text>
                            <Text mt="1" fontWeight={700}>
                              → {situationAnalysisState}
                            </Text>
                            <Divider my="1" />
                          </Box>
                        </VStack>
                      </Collapse>
                      <VStack w="100%">
                        <Button variant={"ghost"} onClick={toggleCollapse}>
                          <Icon
                            as={isCollapsed ? FaChevronDown : FaChevronUp}
                          />
                        </Button>
                      </VStack>
                    </CardBody>
                  </Card>
                  <Card w="100%">
                    <CardBody px="3">
                      <VStack>
                        <Text
                          fontSize={20}
                          fontWeight={700}
                          textAlign={"center"}
                        >
                          그 부분을 가치로 표현한다면 어떤 단어가 좋을까요?
                        </Text>
                        <Textarea
                          h="200px"
                          resize="vertical"
                          placeholder="ex) 감각적 아름다움, 서사"
                          {...register("coreValue")}
                        />
                        <PositiveValues />
                      </VStack>
                    </CardBody>
                  </Card>
                  <Grid
                    pb="5"
                    gap={2}
                    w="100%"
                    gridTemplateColumns={"1fr 1fr 1fr"}
                  >
                    <GridItem>
                      <Button w="100%" onClick={() => setTabIndex(2)}>
                        <Icon as={FaArrowLeft} />
                      </Button>
                    </GridItem>
                    <GridItem>
                      <Button
                        type="submit"
                        isLoading={mutation.isPending}
                        w="100%"
                      >
                        저장
                      </Button>
                    </GridItem>
                    <GridItem w="100%">
                      <Button w="100%" onClick={() => setTabIndex(4)}>
                        <Icon as={FaArrowRight} />
                      </Button>
                    </GridItem>
                  </Grid>
                </VStack>
              </TabPanel>
              <TabPanel px="0">
                <VStack>
                  <Card w="100%">
                    <CardBody py="3">
                      <Collapse in={!isCollapsed} animateOpacity unmountOnExit>
                        <VStack alignItems={"flex-start"}>
                          <Box w="100%">
                            <Text>1. 좋았던 경험을 하나 작성해주세요!</Text>
                            <Text mt="1" fontWeight={700}>
                              → {positiveExperienceState}
                            </Text>
                            <Divider my="1" />
                          </Box>
                          <Box w="100%">
                            <Text>
                              2. 그 경험에서 구체적으로 어떤 감정을 느꼈나요?
                            </Text>
                            <Text mt="1" fontWeight={700}>
                              → {specificEmotionState}
                            </Text>
                            <Divider my="1" />
                          </Box>
                          <Box w="100%">
                            <Text>
                              3. 경험의 어떤 부분이 그 감정을 일으켰나요?
                            </Text>
                            <Text mt="1" fontWeight={700}>
                              → {situationAnalysisState}
                            </Text>
                            <Divider my="1" />
                          </Box>
                          <Box w="100%">
                            <Text>
                              4. 그 부분을 가치로 표현한다면 어떤 단어가
                              좋을까요?
                            </Text>
                            <Text mt="1" fontWeight={700}>
                              → {coreValueState}
                            </Text>
                            <Divider my="1" />
                          </Box>
                        </VStack>
                      </Collapse>
                      <VStack w="100%">
                        <Button variant={"ghost"} onClick={toggleCollapse}>
                          <Icon
                            as={isCollapsed ? FaChevronDown : FaChevronUp}
                          />
                        </Button>
                      </VStack>
                    </CardBody>
                  </Card>
                  <Card w="100%">
                    <CardBody px="3">
                      <VStack>
                        <Text
                          fontSize={20}
                          fontWeight={700}
                          textAlign={"center"}
                        >
                          비슷한 가치와 감정을 느낀 다른 경험들도 작성해보세요!
                        </Text>
                        <Textarea
                          h="200px"
                          resize="vertical"
                          placeholder="ex)&#10;1) 소설이 주는 서사에 감동, 아련함을 느낀 경험이 있다.&#10;2) 영화가 주는 감각적 아름다움과 서사에 몰입감을 느낀 경험이 있다."
                          {...register("similarExperience")}
                        />
                      </VStack>
                    </CardBody>
                  </Card>
                  <Grid
                    pb="5"
                    gap={2}
                    w="100%"
                    gridTemplateColumns={"1fr 1fr 1fr"}
                  >
                    <GridItem>
                      <Button w="100%" onClick={() => setTabIndex(3)}>
                        <Icon as={FaArrowLeft} />
                      </Button>
                    </GridItem>
                    <GridItem>
                      <Button
                        type="submit"
                        isLoading={mutation.isPending}
                        w="100%"
                      >
                        저장
                      </Button>
                    </GridItem>
                    <GridItem w="100%">
                      <Button w="100%" onClick={() => setTabIndex(5)}>
                        <Icon as={FaArrowRight} />
                      </Button>
                    </GridItem>
                  </Grid>
                </VStack>
              </TabPanel>
              <TabPanel px="0">
                <VStack>
                  <Card w="100%">
                    <CardBody py="3">
                      <Collapse in={!isCollapsed} animateOpacity unmountOnExit>
                        <VStack alignItems={"flex-start"}>
                          <Box w="100%">
                            <Text>1. 좋았던 경험을 하나 작성해주세요!</Text>
                            <Text mt="1" fontWeight={700}>
                              → {positiveExperienceState}
                            </Text>
                            <Divider my="1" />
                          </Box>
                          <Box w="100%">
                            <Text>
                              2. 그 경험에서 구체적으로 어떤 감정을 느꼈나요?
                            </Text>
                            <Text mt="1" fontWeight={700}>
                              → {specificEmotionState}
                            </Text>
                            <Divider my="1" />
                          </Box>
                          <Box w="100%">
                            <Text>
                              3. 경험의 어떤 부분이 그 감정을 일으켰나요?
                            </Text>
                            <Text mt="1" fontWeight={700}>
                              → {situationAnalysisState}
                            </Text>
                            <Divider my="1" />
                          </Box>
                          <Box w="100%">
                            <Text>
                              4. 그 부분을 가치로 표현한다면 어떤 단어가
                              좋을까요?
                            </Text>
                            <Text mt="1" fontWeight={700}>
                              → {coreValueState}
                            </Text>
                            <Divider my="1" />
                          </Box>
                          <Box w="100%">
                            <Text>
                              5. 비슷한 가치와 감정을 느낀 다른 경험들도
                              작성해보세요!
                            </Text>
                            <Text mt="1" fontWeight={700}>
                              → {similarExperienceState}
                            </Text>
                            <Divider my="1" />
                          </Box>
                        </VStack>
                      </Collapse>
                      <VStack w="100%">
                        <Button variant={"ghost"} onClick={toggleCollapse}>
                          <Icon
                            as={isCollapsed ? FaChevronDown : FaChevronUp}
                          />
                        </Button>
                      </VStack>
                    </CardBody>
                  </Card>
                  <Card w="100%">
                    <CardBody px="3">
                      <VStack>
                        <Text
                          fontSize={20}
                          fontWeight={700}
                          textAlign={"center"}
                        >
                          비슷한 가치와 감정을 또 느끼려면 어떤 활동들을 해보는
                          게 좋을까요?
                        </Text>
                        <Textarea
                          h="200px"
                          resize="vertical"
                          placeholder="ex) 미술 전시회 감상"
                          {...register("relatedActivity")}
                        />
                      </VStack>
                    </CardBody>
                  </Card>
                  <Grid
                    pb="5"
                    gap={2}
                    w="100%"
                    gridTemplateColumns={"1fr 1fr 1fr"}
                  >
                    <GridItem>
                      <Button w="100%" onClick={() => setTabIndex(4)}>
                        <Icon as={FaArrowLeft} />
                      </Button>
                    </GridItem>
                    <GridItem>
                      <Button
                        type="submit"
                        isLoading={mutation.isPending}
                        w="100%"
                      >
                        저장
                      </Button>
                    </GridItem>
                    <GridItem w="100%">
                      <Button w="100%" onClick={() => setTabIndex(6)}>
                        <Icon as={FaArrowRight} />
                      </Button>
                    </GridItem>
                  </Grid>
                </VStack>
              </TabPanel>
              <TabPanel px="0">
                <VStack>
                  <Card w="100%">
                    <CardBody py="3">
                      <Collapse in={!isCollapsed} animateOpacity unmountOnExit>
                        <VStack alignItems={"flex-start"}>
                          <Box w="100%">
                            <Text>1. 좋았던 경험을 하나 작성해주세요!</Text>
                            <Text mt="1" fontWeight={700}>
                              → {positiveExperienceState}
                            </Text>
                            <Divider my="1" />
                          </Box>
                          <Box w="100%">
                            <Text>
                              2. 그 경험에서 구체적으로 어떤 감정을 느꼈나요?
                            </Text>
                            <Text mt="1" fontWeight={700}>
                              → {specificEmotionState}
                            </Text>
                            <Divider my="1" />
                          </Box>
                          <Box w="100%">
                            <Text>
                              3. 경험의 어떤 부분이 그 감정을 일으켰나요?
                            </Text>
                            <Text mt="1" fontWeight={700}>
                              → {situationAnalysisState}
                            </Text>
                            <Divider my="1" />
                          </Box>
                          <Box w="100%">
                            <Text>
                              4. 그 부분을 가치로 표현한다면 어떤 단어가
                              좋을까요?
                            </Text>
                            <Text mt="1" fontWeight={700}>
                              → {coreValueState}
                            </Text>
                            <Divider my="1" />
                          </Box>
                          <Box w="100%">
                            <Text>
                              5. 비슷한 가치와 감정을 느낀 다른 경험들도
                              작성해보세요!
                            </Text>
                            <Text mt="1" fontWeight={700}>
                              → {similarExperienceState}
                            </Text>
                            <Divider my="1" />
                          </Box>
                          <Box w="100%">
                            <Text>
                              6. 비슷한 가치와 감정을 또 느끼려면 어떤 활동들을
                              해보는 게 좋을까요?
                            </Text>
                            <Text mt="1" fontWeight={700}>
                              → {relatedActivityState}
                            </Text>
                            <Divider my="1" />
                          </Box>
                        </VStack>
                      </Collapse>
                      <VStack w="100%">
                        <Button variant={"ghost"} onClick={toggleCollapse}>
                          <Icon
                            as={isCollapsed ? FaChevronDown : FaChevronUp}
                          />
                        </Button>
                      </VStack>
                    </CardBody>
                  </Card>
                  <Card w="100%">
                    <CardBody>
                      <VStack>
                        <Text
                          fontSize={18}
                          fontWeight={700}
                          textAlign={"center"}
                        >
                          다음의 3가지 성찰 키워드를 완성해보세요!
                        </Text>
                        <Divider />
                        <FormControl>
                          <FormLabel>나에게 의미있는 가치</FormLabel>
                          <Input
                            textAlign={"center"}
                            placeholder="ex) 감각적 아름다움, 서사"
                            {...register("keywordPositiveValue")}
                          />
                        </FormControl>
                        <FormControl>
                          <FormLabel>그 가치에 내가 느끼는 감정</FormLabel>
                          <Input
                            textAlign={"center"}
                            placeholder="ex) 신남, 아련함, 몰입감"
                            {...register("keywordPositiveEmotion")}
                          />
                        </FormControl>
                        <FormControl>
                          <FormLabel>그 가치를 실천할 수 있는 활동들</FormLabel>
                          <Input
                            textAlign={"center"}
                            placeholder="ex) 노래 듣기, 소설 읽기, 영화 감상, 미술 전시회 감상"
                            {...register("keywordActivity")}
                          />
                        </FormControl>
                      </VStack>
                    </CardBody>
                  </Card>
                  <Card w="100%">
                    <CardBody>
                      <VStack>
                        <Text
                          fontSize={20}
                          fontWeight={700}
                          textAlign={"center"}
                        >
                          나의 성찰
                        </Text>
                        <Divider />
                        <Text textAlign={"center"} lineHeight={2}>
                          나는{" "}
                          <Text
                            as="span"
                            my="3"
                            fontSize={18}
                            fontWeight={"700"}
                            bg={highlightColor}
                          >
                            {inputPositiveValue || "감각적 아름다움, 서사"}
                          </Text>{" "}
                          에{" "}
                          <Text
                            as="span"
                            my="3"
                            fontSize={18}
                            fontWeight={"700"}
                            bg={highlightColor}
                          >
                            {inputPositiveEmotion || "신남, 아련함, 몰입감"}
                          </Text>{" "}
                          을(를) 느낀다.
                        </Text>
                      </VStack>
                    </CardBody>
                  </Card>
                  <Card w="100%">
                    <CardBody>
                      <VStack>
                        <Text
                          fontSize={20}
                          fontWeight={700}
                          textAlign={"center"}
                        >
                          나의 추구
                        </Text>
                        <Divider />
                        <Text textAlign={"center"} lineHeight={2}>
                          나는{" "}
                          <Text
                            as="span"
                            my="3"
                            fontSize={18}
                            fontWeight={"700"}
                            bg={highlightColor}
                          >
                            {inputActivity ||
                              "노래 듣기, 소설 읽기, 영화 감상, 미술 전시회 감상"}
                          </Text>{" "}
                          을(를) 통해{" "}
                          <Text
                            as="span"
                            my="3"
                            fontSize={18}
                            fontWeight={"700"}
                            bg={highlightColor}
                          >
                            {inputPositiveValue || "감각적 아름다움, 서사"}
                          </Text>{" "}
                          을(를) 추구할 것이다.
                        </Text>
                      </VStack>
                    </CardBody>
                  </Card>
                  <Card w="100%">
                    <CardBody>
                      <VStack>
                        <Text
                          fontSize={18}
                          fontWeight={700}
                          textAlign={"center"}
                        >
                          이번 성찰의 제목을 작성해주세요!
                        </Text>
                        <Input
                          textAlign={"center"}
                          placeholder="긍정경험 성찰"
                          {...register("title")}
                        />
                      </VStack>
                    </CardBody>
                  </Card>
                  <Grid
                    pb="5"
                    gap={2}
                    w="100%"
                    gridTemplateColumns={"1fr 1fr 1fr"}
                  >
                    <GridItem>
                      <Button w="100%" onClick={() => setTabIndex(5)}>
                        <Icon as={FaArrowLeft} />
                      </Button>
                    </GridItem>
                    <GridItem>
                      <Button
                        type="submit"
                        isLoading={mutation.isPending}
                        w="100%"
                      >
                        저장
                      </Button>
                    </GridItem>
                    <GridItem w="100%"></GridItem>
                  </Grid>
                </VStack>
              </TabPanel>
            </TabPanels>
          )}
        </Tabs>
      </VStack>
    </Container>
  );
}
