import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  Card,
  CardBody,
  Container,
  Flex,
  Icon,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  Textarea,
  useDisclosure,
  useToast,
  VStack,
} from "@chakra-ui/react";
import { useMutation } from "@tanstack/react-query";
import React, { useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { FaChevronLeft } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import {
  IUploadNegExpReflectionError,
  IUploadNegExpReflectionSuccess,
  IUploadNegExpReflectionVariables,
  uploadNegExpReflection,
} from "./api";

import ProtectedPage from "../components/ProtectedPage";

interface IForm {
  negativeExperience: string;
  title: string;
}

export default function UploadNegExperience() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const containerRef = useRef(null);
  const [isSave, setIsSave] = useState(true);
  const { register, watch, handleSubmit } = useForm<IForm>();
  const navigate = useNavigate();
  const toast = useToast();
  const mutation = useMutation<
    IUploadNegExpReflectionSuccess,
    IUploadNegExpReflectionError,
    IUploadNegExpReflectionVariables
  >({
    mutationFn: uploadNegExpReflection,
    onSuccess: (data) => {
      toast({
        title: `성찰이 업로드 되었습니다!`,
        status: "success",
        position: "top",
        isClosable: true,
        duration: 3000,
      });
      if (isSave) {
        navigate("/reflection/experiences");
      } else {
        navigate(`/reflection/experiences/negative/${data.pk}/edit`, {
          state: { isFromUpload: true },
        });
      }
    },
    onError: (error) => console.log(error),
  });

  const onSubmit = ({ negativeExperience, title }: IForm) => {
    mutation.mutate({
      title: title,
      negative_experience: negativeExperience,
      specific_emotion: "",
      situation_analysis: "",
      negative_value: "",
      similar_experience: "",
      positive_value: "",
      related_activity: "",
      reflection_keyword: {
        negative_value: "",
        negative_emotion: "",
        positive_value: "",
        activity: "",
      },
    });
  };

  const {
    isOpen: isCancelAlertOpen,
    onOpen: onCancelAlertOpen,
    onClose: onCancelAlertClose,
  } = useDisclosure();
  const cancelRef = React.useRef(null);

  const watchNegativeExperience = watch("negativeExperience");
  const watchTitle = watch("title");

  const handleCancelAlertOpen = () => {
    if (watchNegativeExperience || watchTitle) {
      onCancelAlertOpen();
    } else {
      navigate(`/reflection/experiences`);
    }
  };

  const handleCancel = () => {
    onCancelAlertClose();
    navigate(`/reflection/experiences`);
  };

  return (
    <ProtectedPage>
      <Container>
        <Flex>
          <Flex
            py="5"
            px="1"
            onClick={handleCancelAlertOpen}
            _hover={{ cursor: "pointer" }}
          >
            <Icon as={FaChevronLeft} />
          </Flex>
        </Flex>
        <AlertDialog
          isCentered
          isOpen={isCancelAlertOpen}
          leastDestructiveRef={cancelRef}
          onClose={onCancelAlertClose}
        >
          <AlertDialogOverlay>
            <AlertDialogContent>
              <AlertDialogHeader fontSize="lg" fontWeight="bold">
                부정경험 성찰 닫기
              </AlertDialogHeader>
              <AlertDialogBody>저장하지 않고 닫으시겠습니까?</AlertDialogBody>
              <AlertDialogFooter>
                <Button ref={cancelRef} onClick={onCancelAlertClose}>
                  취소
                </Button>
                <Button colorScheme="red" onClick={handleCancel} ml={3}>
                  닫기
                </Button>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialogOverlay>
        </AlertDialog>
        <VStack as="form" onSubmit={handleSubmit(onSubmit)} ref={containerRef}>
          <Card w="100%">
            <CardBody px="3">
              <VStack>
                <Text fontSize={20} fontWeight={700} textAlign={"center"}>
                  안좋았던 경험을 하나 작성해주세요.
                </Text>
                <Textarea
                  {...register("negativeExperience")}
                  h="200px"
                  resize="vertical"
                  placeholder="ex) 직장 동료에게 책에서 읽은 내용을 전해줬는데, 내 말을 들어주지 않아 답답했다."
                />
              </VStack>
            </CardBody>
          </Card>
          <Button w="100%" fontSize="16" onClick={onOpen}>
            저장
          </Button>
          <Modal
            isOpen={isOpen}
            onClose={onClose}
            isCentered
            portalProps={{ containerRef }}
          >
            <ModalOverlay />
            <ModalContent mx="4">
              <ModalHeader></ModalHeader>
              <ModalCloseButton />
              <ModalBody>
                <Text textAlign={"center"} mb="3">
                  이번 성찰의 제목을 지어주세요!
                </Text>
                <Input {...register("title")} />
              </ModalBody>
              <ModalFooter>
                <VStack w="100%">
                  <Button
                    w="100%"
                    fontSize="16"
                    type="submit"
                    isLoading={mutation.isPending}
                    colorScheme="blue"
                  >
                    저장 →
                  </Button>
                  <Button
                    w="100%"
                    fontSize="16"
                    type="submit"
                    isLoading={mutation.isPending}
                    colorScheme="orange"
                    onClick={() => setIsSave(false)}
                  >
                    더 깊이 성찰하기 →
                  </Button>
                </VStack>
              </ModalFooter>
            </ModalContent>
          </Modal>
        </VStack>
      </Container>
    </ProtectedPage>
  );
}
